import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import { DropdownMenuGroupType } from '@/components/molecules/DropdownMenu';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import { useCustomZip, useUserZip } from '@/hooks/useUserZip';
import { GOOGLE_TRACK_INFO, POPULAR_MAKES } from '@/lib/constants';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { LocationPopOutModal } from '../LocationPopOutModal/LocationPopOutModal';

type HomePageMakeModelsProps = {
  makes: string[];
};

export const HomePageMakeModelsBar: FC<HomePageMakeModelsProps> = (props) => {
  const { makes } = props;
  const sortedMakes = useMemo(() => {
    const popularMakes: DropdownMenuGroupType = {
      category: 'POPULAR MAKES',
      index: 0,
      menuItems: makes
        .filter((makeName) => POPULAR_MAKES.includes(makeName))
        .sort((a, b) => POPULAR_MAKES.indexOf(a) - POPULAR_MAKES.indexOf(b))
        .map((makeName) => ({ text: makeName, value: makeName })),
    };
    const allMakes: DropdownMenuGroupType = {
      category: 'ALL MAKES',
      index: 1,
      menuItems: makes
        .sort((a, b) => a.localeCompare(b))
        .map((makeName) => ({ text: makeName, value: makeName })),
    };

    return [popularMakes, allMakes];
  }, [makes]);

  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);
  const [models, setModels] = useState<DropdownMenuGroupType[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [searchSlug, setSearchSlug] = useState<string>('');
  const { zip: ipZip } = useUserZip() || {};
  const { postal, city, state, country } = ipZip || {};
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const customZip = useCustomZip();

  const fetchModels = useCallback(
    async (query: string) => {
      try {
        const response = await fetch(
          `/api/inventory/listings/models?makes=${query}`
        );
        if (response.ok) {
          const data: Record<string, string[]> = await response.json();
          if (sortedMakes && sortedMakes.length > 0) {
            const newModels: DropdownMenuGroupType[] = Object.keys(data)
              .map((makeKey) => {
                return {
                  category: makeKey,
                  index: POPULAR_MAKES.includes(makeKey) ? 0 : 1,
                  menuItems: data[makeKey].map((model) => ({
                    text: model,
                    value: model,
                  })),
                };
              })
              .sort((a, b) => {
                const allMakes = [
                  ...sortedMakes[0].menuItems,
                  ...sortedMakes[1].menuItems,
                ];
                return (
                  allMakes.findIndex((make) => make.value === a.category) -
                  allMakes.findIndex((make) => make.value === b.category)
                );
              });

            setModels(newModels);
          } else {
            setModels([]);
            setSelectedModels([]);
          }
        }
      } catch (err) {
        setSelectedModels([]);
        setModels([]);
      }
    },
    [sortedMakes]
  );

  useEffect(() => {
    if (selectedMakes.length > 0) {
      fetchModels(selectedMakes.join(','));
    } else {
      setModels([]);
    }
  }, [selectedMakes, fetchModels]);

  useEffect(() => {
    let slug = '';
    if (postal && country === 'US') {
      slug = `/postal/${postal}`;
    }
    if (selectedMakes.length > 0) {
      slug += `/make/${encodeURIComponent(selectedMakes.join(','))}`;
    }
    if (selectedModels.length > 0) {
      slug += `/model/${encodeURIComponent(selectedModels.join(','))}`;
    }
    setSearchSlug(slug);
  }, [selectedMakes, selectedModels, postal, country]);

  const handleSetSelectedMakes = useCallback((newSelectedMakes: string[]) => {
    setSelectedMakes(newSelectedMakes);
  }, []);
  const handleSetSelectedModels = useCallback((newSelectedModels: string[]) => {
    setSelectedModels(newSelectedModels);
  }, []);

  return (
    <>
      <div className="flex w-full flex-col gap-m rounded-lg bg-white p-m ml:h-[72px] ml:flex-row">
        <div className="flex h-[48px] flex-grow">
          <FormSelect
            className="flex-grow"
            inputClassName="h-full"
            placeholder="All Makes"
            disabled={sortedMakes?.length === 0}
            showCount={false}
            options={sortedMakes || []}
            value={selectedMakes}
            autoCompleteInput={true}
            onChange={(newSelectedMakes) => {
              handleSetSelectedMakes(newSelectedMakes);
            }}
            variant={DropdownMenuItemVariants.MultiSelect}
            clearMenu={() => handleSetSelectedMakes([])}
          />
        </div>
        <div className="flex h-[48px] flex-grow">
          <FormSelect
            className="w-full"
            inputClassName="h-full"
            placeholder="Select model(s)"
            options={models}
            disabled={models.length === 0}
            value={selectedModels}
            autoCompleteInput={true}
            onChange={(newSelectedModels) =>
              handleSetSelectedModels(newSelectedModels)
            }
            variant={DropdownMenuItemVariants.MultiSelect}
            clearMenu={() => handleSetSelectedModels([])}
          />
        </div>
        <div className="flex h-[48px] w-full ml:h-full ml:w-1/5">
          <Button
            className="w-full whitespace-nowrap"
            variant={ButtonVariants.Primary}
            analyticsEvent={{
              ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
              pageTarget: 'search',
            }}
            onClick={() => {
              window.location.href = `/search/${searchSlug}`;
            }}
          >
            Search EVs
          </Button>
        </div>
      </div>

      <div className="flex w-full justify-between pt-l">
        {city && state && country && (
          <div
            className="fit-content flex cursor-pointer items-center"
            onClick={() => setLocationModalOpen(true)}
          >
            <MapPinIcon
              stroke="#FFFFFF"
              className="pr-s"
              width={24}
              height={24}
            />
            <span className="text-body1Medium text-neutral-0">
              {customZip.address || `${city}, ${state}, ${country}`}
            </span>
            <LocationPopOutModal
              open={locationModalOpen}
              initialZipCode={customZip.zip || ipZip?.postal}
              shouldStayOrigin={true}
              persistZip={true}
              close={() => {
                setLocationModalOpen(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
